import { useEffect, useState } from "react"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table"
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";
import { RiderModel } from "@/interfaces/RiderModel";
import { Button } from "../ui/button";
import { useNavigate } from "react-router";

function Riders() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [riders, setRiders] = useState<RiderModel[]>();
    const navigate = useNavigate();

    const getRiders = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/rider/screening",
                params: {},
                body: {},
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)

            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Riders Application",
                    description: response.response.data.message,
                });
            } else {
                if (response.data[0]['itm']) {
                    const unescapedJsonStr = response.data[0]['itm'].replace(/\\"/g, '"');
                    const jsonData = JSON.parse(unescapedJsonStr);
                    setRiders(jsonData)
                }
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
        // } catch (error) {
        //     throw new Error(`Error fetching data: ${error}`);
        // }
    }

    // const handleAcceptClick = (userID: number) => {
    //     acceptRider(userID)
    // }

    // const handleDeclineClick = (userID: number) => {
    //     declineRider(userID)
    // }

    const handleViewClick = (userID: number) => {
        const fill = riders?.find((i) => i.user_id === userID);
        navigate("/admin/riders/" + userID, { state: { riderData: fill } });
    }

    const handleRiderStatusClick = () => {
        navigate("/admin/riders/status")
    }

    // const declineRider = async (userID: number) => {
    //     try {
    //         const apiParams: APICallParams = {
    //             verb: "POST",
    //             url: "/rider/screening_approval",
    //             params: {},
    //             body: {
    //                 jsondata: { user_id: userID, isaccepted: 0 }
    //             }
    //         };
    //         const response = await makeWithOutAuthenticatedAPICall(apiParams)
    //         if (response.status !== 200) {
    //             toast({
    //                 variant: "destructive",
    //                 title: "Rider",
    //                 description: response.response.data.message,
    //             });
    //         } else {
    //             toast({
    //                 title: "Decline",
    //                 description: "Successfully",
    //             });
    //         }
    //     } catch (error) {
    //         throw new Error(`Error fetching data: ${error}`);
    //     }
    // }

    // const acceptRider = async (userID: number) => {
    //     try {
    //         const apiParams: APICallParams = {
    //             verb: "POST",
    //             url: "/rider/screening_approval",
    //             params: {},
    //             body: {
    //                 jsondata: { user_id: userID, isaccepted: 1 }
    //             },
    //         };
    //         const response = await makeWithOutAuthenticatedAPICall(apiParams)
    //         if (response.status !== 200) {
    //             toast({
    //                 variant: "destructive",
    //                 title: "Rider",
    //                 description: response.response.data.message,
    //             });
    //         } else {
    //             toast({
    //                 title: "Accept",
    //                 description: "Successfully",
    //             });
    //         }
    //     } catch (error) {
    //         throw new Error(`Error fetching data: ${error}`);
    //     }
    // }

    useEffect(() => {
        getRiders();
    }, [riders]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <div className="flex flex-row justify-between items-center">
                <strong className="text-gray-700 font-medium">Pending Applications</strong>
                <Button variant="destructive" onClick={() => handleRiderStatusClick()}>
                    Applications
                </Button>
            </div>
            <Table>
                <TableCaption>A list of your Rrider Applicant.</TableCaption>
                <TableHeader>
                    <TableHead className="w-[100px]">ID</TableHead>
                    <TableHead>Image                    </TableHead>
                    <TableHead>First Name</TableHead>
                    <TableHead>Last Name</TableHead>
                    <TableHead>Vehicle</TableHead>
                    <TableHead className="w-[100px]">Actions</TableHead>
                </TableHeader>
                <TableBody>
                    {riders?.map((i) => (
                        <TableRow key={i.user_id}>
                            <TableCell className="font-medium">{i.user_id}</TableCell>
                            <TableCell>
                                <img
                                    src={i.selfie}
                                    className="mx-auto w-10 items-center"
                                    alt={i.selfie}
                                />
                            </TableCell>
                            <TableCell>{i.fname}</TableCell>
                            <TableCell>{i.lname}</TableCell>
                            <TableCell>

                                <img
                                    src={i.vehicle_img}
                                    className="mx-auto w-10 items-center"
                                    alt={i.vehicle_img}
                                />
                            </TableCell>
                            <TableCell>
                                <Button variant="outline" onClick={() => handleViewClick(i.user_id)}>
                                    View
                                </Button>
                                {/* <div className="flex flex-row gap-3">
                                    <Button variant="outline" onClick={() => handleViewClick(i.user_id)}>
                                        View
                                    </Button>
                                    <Button onClick={() => handleAcceptClick(i.user_id)}>
                                        Accept
                                    </Button>
                                    <Button variant="destructive" onClick={() => handleDeclineClick(i.user_id)}>
                                        Decline
                                    </Button>
                                </div> */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default Riders